define("ember-composable-helpers/helpers/has-next", ["exports", "@ember/component/helper", "@ember/utils", "ember-composable-helpers/helpers/next", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _utils, _next, _isEqual, _getValueArrayAndUseDeepEqualFromParams, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasNext = hasNext;
  _exports.default = void 0;
  function hasNext(currentValue, maybeArray) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let array = (0, _asArray.default)(maybeArray);
    let nextValue = (0, _next.next)(currentValue, array, useDeepEqual);
    let isNotSameValue = !(0, _isEqual.default)(nextValue, currentValue, useDeepEqual);
    return isNotSameValue && (0, _utils.isPresent)(nextValue);
  }
  var _default = (0, _helper.helper)(function (params) {
    let {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return hasNext(currentValue, array, useDeepEqual);
  });
  _exports.default = _default;
});