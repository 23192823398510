define("ember-feature-flags/helpers/feature-flag", ["exports", "@ember/component/helper", "@ember/service", "@ember/string"], function (_exports, _helper, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _helper.default.extend({
    features: (0, _service.inject)(),
    compute(_ref) {
      let [flag] = _ref;
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
      this.set('_observedFlag', (0, _string.camelize)(flag));
      this.get('features').addObserver(this._observedFlag, this, 'recompute');
      return this.get('features').isEnabled(flag);
    },
    _observedFlag: null,
    willDestroy() {
      this._super(...arguments);
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
    }
  });
  _exports.default = _default;
});