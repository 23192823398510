define("ember-composable-helpers/helpers/values", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.values = values;
  _exports.default = void 0;
  function values(_ref) {
    let [object] = _ref;
    if (!object) {
      return object;
    }
    return Object.values(object);
  }
  var _default = (0, _helper.helper)(values);
  _exports.default = _default;
});