define("ember-composable-helpers/helpers/pipe", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/is-promise"], function (_exports, _helper, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeFunction = invokeFunction;
  _exports.pipe = pipe;
  _exports.default = void 0;
  function invokeFunction(acc, curr) {
    if ((0, _isPromise.default)(acc)) {
      return acc.then(curr);
    }
    return curr(acc);
  }
  function pipe() {
    let actions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return actions.reduce((acc, curr, idx) => {
        if (idx === 0) {
          return curr(...args);
        }
        return invokeFunction(acc, curr);
      }, undefined);
    };
  }
  var _default = (0, _helper.helper)(pipe);
  _exports.default = _default;
});