define("ember-radio-button/components/radio-button", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    <label class="ember-radio-button {{if this.checked this.checkedClass}} {{this.joinedClassNames}}" for={{@radioId}}>
      <RadioButtonInput
        class={{@radioClass}}
        id={{@radioId}}
        autofocus={{@autofocus}}
        disabled={{@disabled}}
        name={{@name}}
        required={{@required}}
        tabindex={{@tabindex}}
        @groupValue={{@groupValue}}
        checked={{this.checked}}
        @value={{@value}}
        aria-labelledby={{@ariaLabelledby}}
        aria-describedby={{@ariaDescribedby}}
        @changed={{this.changed}}
      />
      {{yield}}
    </label>
  {{else}}
    <RadioButtonInput
      class={{@radioClass}}
      id={{@radioId}}
      autofocus={{@autofocus}}
      disabled={{@disabled}}
      name={{@name}}
      required={{@required}}
      tabindex={{@tabindex}}
      @groupValue={{@groupValue}}
      checked={{this.checked}}
      @value={{@value}}
      aria-labelledby={{@ariaLabelledby}}
      aria-describedby={{@ariaDescribedby}}
      @changed={{this.changed}}
    />
  {{/if}}
  
  */
  {
    "id": "lyrS1sKC",
    "block": "[[[41,[48,[30,12]],[[[1,\"  \"],[10,\"label\"],[15,0,[29,[\"ember-radio-button \",[52,[30,0,[\"checked\"]],[30,0,[\"checkedClass\"]]],\" \",[30,0,[\"joinedClassNames\"]]]]],[15,\"for\",[30,1]],[12],[1,\"\\n    \"],[8,[39,2],[[16,0,[30,2]],[16,1,[30,1]],[16,\"autofocus\",[30,3]],[16,\"disabled\",[30,4]],[16,3,[30,5]],[16,\"required\",[30,6]],[16,\"tabindex\",[30,7]],[16,\"checked\",[30,0,[\"checked\"]]],[16,\"aria-labelledby\",[30,8]],[16,\"aria-describedby\",[30,9]]],[[\"@groupValue\",\"@value\",\"@changed\"],[[30,10],[30,11],[30,0,[\"changed\"]]]],null],[1,\"\\n    \"],[18,12,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[16,0,[30,2]],[16,1,[30,1]],[16,\"autofocus\",[30,3]],[16,\"disabled\",[30,4]],[16,3,[30,5]],[16,\"required\",[30,6]],[16,\"tabindex\",[30,7]],[16,\"checked\",[30,0,[\"checked\"]]],[16,\"aria-labelledby\",[30,8]],[16,\"aria-describedby\",[30,9]]],[[\"@groupValue\",\"@value\",\"@changed\"],[[30,10],[30,11],[30,0,[\"changed\"]]]],null],[1,\"\\n\"]],[]]]],[\"@radioId\",\"@radioClass\",\"@autofocus\",\"@disabled\",\"@name\",\"@required\",\"@tabindex\",\"@ariaLabelledby\",\"@ariaDescribedby\",\"@groupValue\",\"@value\",\"&default\"],false,[\"if\",\"has-block\",\"radio-button-input\",\"yield\"]]",
    "moduleName": "ember-radio-button/components/radio-button.hbs",
    "isStrictMode": false
  });
  let RadioButtonComponent = (_class = class RadioButtonComponent extends _component2.default {
    // value - passed in, required, the value for this radio button
    // groupValue - passed in, required, the currently selected value

    // optionally passed in:
    // disabled - boolean
    // required - boolean
    // name - string
    // radioClass - string
    // radioId - string
    // ariaLabelledby - string
    // ariaDescribedby - string

    get joinedClassNames() {
      const classNames = this.args.classNames;
      if (classNames && classNames.length && classNames.join) {
        return classNames.join(' ');
      }
      return classNames;
    }
    get checkedClass() {
      return this.args.checkedClass || 'checked';
    }
    get checked() {
      return (0, _utils.isEqual)(this.args.groupValue, this.args.value);
    }
    changed(newValue) {
      let changedAction = this.args.changed;

      // providing a closure action is optional
      if (changedAction) {
        changedAction(newValue);
      }
    }
  }, (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changed"), _class.prototype), _class);
  _exports.default = RadioButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioButtonComponent);
});