define("@ember-data/model/-private", ["exports", "@ember-data/model/has-many-c1bfdebf", "@ember/application"], function (_exports, _hasManyC1bfdebf, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._modelForMixin = modelForMixin;
  _exports.diffArray = diffArray;
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.M;
    }
  });
  Object.defineProperty(_exports, "Errors", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.E;
    }
  });
  Object.defineProperty(_exports, "LEGACY_SUPPORT", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.L;
    }
  });
  Object.defineProperty(_exports, "ManyArray", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.R;
    }
  });
  Object.defineProperty(_exports, "PromiseBelongsTo", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.P;
    }
  });
  Object.defineProperty(_exports, "PromiseManyArray", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.c;
    }
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.b;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _hasManyC1bfdebf.h;
    }
  });
  /*
      In case someone defined a relationship to a mixin, for example:
      ```
        import Model, { belongsTo, hasMany } from '@ember-data/model';
        import Mixin from '@ember/object/mixin';
  
        class CommentModel extends Model {
          @belongsTo('commentable', { polymorphic: true }) owner;
        }
  
        let Commentable = Mixin.create({
          @hasMany('comment') comments;
        });
      ```
      we want to look up a Commentable class which has all the necessary
      relationship meta data. Thus, we look up the mixin and create a mock
      Model, so we can access the relationship CPs of the mixin (`comments`)
      in this case
    */
  function modelForMixin(store, normalizedModelName) {
    let owner = (0, _application.getOwner)(store);
    let MaybeMixin = owner.factoryFor(`mixin:${normalizedModelName}`);
    let mixin = MaybeMixin && MaybeMixin.class;
    if (mixin) {
      let ModelForMixin = _hasManyC1bfdebf.M.extend(mixin);
      ModelForMixin.__isMixin = true;
      ModelForMixin.__mixin = mixin;
      //Cache the class as a model
      owner.register('model:' + normalizedModelName, ModelForMixin);
    }
    return owner.factoryFor(`model:${normalizedModelName}`);
  }

  /**
    @module @ember-data/model
  */

  /**
    @method diffArray
    @internal
    @param {Array} oldArray the old array
    @param {Array} newArray the new array
    @return {hash} {
        firstChangeIndex: <integer>,  // null if no change
        addedCount: <integer>,        // 0 if no change
        removedCount: <integer>       // 0 if no change
      }
  */
  function diffArray(oldArray, newArray) {
    const oldLength = oldArray.length;
    const newLength = newArray.length;
    const shortestLength = Math.min(oldLength, newLength);
    let firstChangeIndex = null; // null signifies no changes

    // find the first change
    for (let i = 0; i < shortestLength; i++) {
      // compare each item in the array
      if (oldArray[i] !== newArray[i]) {
        firstChangeIndex = i;
        break;
      }
    }
    if (firstChangeIndex === null && newLength !== oldLength) {
      // no change found in the overlapping block
      // and array lengths differ,
      // so change starts at end of overlap
      firstChangeIndex = shortestLength;
    }
    let addedCount = 0;
    let removedCount = 0;
    if (firstChangeIndex !== null) {
      // we found a change, find the end of the change
      let unchangedEndBlockLength = shortestLength - firstChangeIndex;
      // walk back from the end of both arrays until we find a change
      for (let i = 1; i <= shortestLength; i++) {
        // compare each item in the array
        if (oldArray[oldLength - i] !== newArray[newLength - i]) {
          unchangedEndBlockLength = i - 1;
          break;
        }
      }
      addedCount = newLength - unchangedEndBlockLength - firstChangeIndex;
      removedCount = oldLength - unchangedEndBlockLength - firstChangeIndex;
    }
    return {
      firstChangeIndex,
      addedCount,
      removedCount
    };
  }
});