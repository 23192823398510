define("ember-composable-helpers/helpers/drop", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.drop = drop;
  _exports.default = void 0;
  function drop(_ref) {
    let [dropAmount, array] = _ref;
    return (0, _asArray.default)(array).slice(dropAmount);
  }
  var _default = (0, _helper.helper)(drop);
  _exports.default = _default;
});