define("@ember-data/adapter/-private", ["exports", "@ember-data/adapter/serialize-into-hash-b9092b25", "@ember-data/adapter/build-url-mixin-18db8c8b"], function (_exports, _serializeIntoHashB9092b, _buildUrlMixin18db8c8b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "determineBodyPromise", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.d;
    }
  });
  Object.defineProperty(_exports, "fetch", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.g;
    }
  });
  Object.defineProperty(_exports, "parseResponseHeaders", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.p;
    }
  });
  Object.defineProperty(_exports, "serializeIntoHash", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.b;
    }
  });
  Object.defineProperty(_exports, "serializeQueryParams", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.s;
    }
  });
  Object.defineProperty(_exports, "setupFastboot", {
    enumerable: true,
    get: function () {
      return _serializeIntoHashB9092b.a;
    }
  });
  Object.defineProperty(_exports, "BuildURLMixin", {
    enumerable: true,
    get: function () {
      return _buildUrlMixin18db8c8b.B;
    }
  });
});