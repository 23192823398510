define("ember-composable-helpers/helpers/range", ["exports", "@ember/component/helper", "@ember/utils", "ember-composable-helpers/utils/comparison"], function (_exports, _helper, _utils, _comparison) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.range = range;
  _exports.default = void 0;
  function range(_ref) {
    let [min, max, isInclusive] = _ref;
    isInclusive = (0, _utils.typeOf)(isInclusive) === 'boolean' ? isInclusive : false;
    let numbers = [];
    if (min < max) {
      let testFn = isInclusive ? _comparison.lte : _comparison.lt;
      for (let i = min; testFn(i, max); i++) {
        numbers.push(i);
      }
    }
    if (min > max) {
      let testFn = isInclusive ? _comparison.gte : _comparison.gt;
      for (let i = min; testFn(i, max); i--) {
        numbers.push(i);
      }
    }
    if (min === max && isInclusive) {
      numbers.push(max);
    }
    return numbers;
  }
  var _default = (0, _helper.helper)(range);
  _exports.default = _default;
});