define("@ember/string/cache", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Cache {
    constructor(limit, func, store) {
      (0, _defineProperty2.default)(this, "size", 0);
      (0, _defineProperty2.default)(this, "misses", 0);
      (0, _defineProperty2.default)(this, "hits", 0);
      this.limit = limit;
      this.func = func;
      this.store = store;
      this.store = store || new Map();
    }
    get(key) {
      let value = this.store.get(key);
      if (this.store.has(key)) {
        this.hits++;
        return this.store.get(key);
      } else {
        this.misses++;
        value = this.set(key, this.func(key));
      }
      return value;
    }
    set(key, value) {
      if (this.limit > this.size) {
        this.size++;
        this.store.set(key, value);
      }
      return value;
    }
    purge() {
      this.store.clear();
      this.size = 0;
      this.hits = 0;
      this.misses = 0;
    }
  }
  _exports.default = Cache;
});